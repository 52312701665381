import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import React from "react"

import { ModalProps } from "./index"

const parseContent = (
    content:
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | boolean
) => {
    if (!content) return null

    if (typeof content === "string") {
        const parts = content.split(/(<b>.*?<\/b>|<br>|<i>.*?<\/i>|<small>.*?<\/small>)/)
        return parts.map((part: string, index: React.Key | null | undefined) => {
            if (part.startsWith("<b>") && part.endsWith("</b>")) {
                return <strong key={index}>{part.slice(3, -4)}</strong>
            } else if (part === "<br>") {
                return <span style={{ display: "block", height: "10px" }} key={index}></span>
            } else if (part.startsWith("<i>") && part.endsWith("</i>")) {
                return <i key={index}>{part.slice(3, -4)}</i>
            } else if (part.startsWith("<small>") && part.endsWith("</small>")) {
                return <small key={index}>{part.slice(7, -8)}</small>
            }
            return part
        })
    } else {
        return content
    }
}

const Confirm: React.FC<ModalProps> = ({ open, close, onCancel, onOk, title, content }) => {
    return (
        <Dialog
            data-test="confirm"
            maxWidth="md"
            open={open}
            onClose={() => {
                close()
                onCancel?.()
            }}>
            <DialogTitle data-test="confirm__title">
                <Typography sx={{ fontSize: "24px" }}>{title}</Typography>
            </DialogTitle>
            {content && (
                <DialogContent data-test="confirm__content">
                    <Typography sx={{ fontSize: "18px" }}> {parseContent(content)}</Typography>
                </DialogContent>
            )}
            <DialogActions sx={{ px: "24px" }}>
                <Button
                    data-test="confirm__cancel-button"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        close()
                        onCancel?.()
                    }}
                    sx={{ fontSize: "16px" }}>
                    Cancel
                </Button>
                <Button
                    data-test="confirm__ok-button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        close()
                        onOk?.()
                    }}
                    sx={{ fontSize: "16px" }}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Confirm
