import {
    Menu,
    MenuDivider,
    OpenFile,
    Popover,
    Position,
    TextDirection,
    ThemeContext,
    type Toggle,
} from "@react-pdf-viewer/core"
import { SelectionMode } from "@react-pdf-viewer/selection-mode"
import * as React from "react"
import { MoreIcon, ToolbarSlot } from "@react-pdf-viewer/toolbar"
import { IconButton, MenuItem, Tooltip, Typography } from "@mui/material"
import { OpenInNew } from "@mui/icons-material"
import { onDownload } from "@/utils/download"
import { getFilePlugin } from "@react-pdf-viewer/get-file"
import { useFiles } from "@/hooks/useFiles"
import { useEffect, useState } from "react"

const PORTAL_OFFSET = { left: 0, top: 8 }

export const CustomMoreActionsPopover: React.FC<{
    toolbarSlot: ToolbarSlot
    fileName?: string
    id?: string
}> = ({ toolbarSlot, fileName, id }) => {
    const { direction } = React.useContext(ThemeContext)
    const portalPosition = direction === TextDirection.RightToLeft ? Position.BottomLeft : Position.BottomRight
    const {
        EnterFullScreenMenuItem,
        GoToNextPageMenuItem,
        GoToPreviousPageMenuItem,
        OpenMenuItem,
        PrintMenuItem,
        RotateBackwardMenuItem,
        RotateForwardMenuItem,
        SwitchSelectionModeMenuItem,
        SwitchThemeMenuItem,
    } = toolbarSlot

    const { sourceUrl } = useFiles({ enabled: false })
    const [fileSourceUrl, setFileSourceUrl] = useState<string | undefined>(undefined)

    const getSourceUrlWithCache = async (fileId: string): Promise<string | undefined> => {
        try {
            const response = await sourceUrl.mutateAsync({
                fileId: fileId,
            })
            return response
        } catch (error) {
            console.error("Error fetching source URL:", error)
            return undefined
        }
    }

    useEffect(() => {
        if (!fileSourceUrl && id) {
            getSourceUrlWithCache(id).then(url => {
                if (url) {
                    setFileSourceUrl(url)
                }
            })
        }
    }, [id, fileSourceUrl])

    const renderTarget = (toggle: Toggle, opened: boolean): React.ReactElement => {
        return (
            <Tooltip title="More actions">
                <IconButton onClick={() => toggle()}>
                    <MoreIcon />
                </IconButton>
            </Tooltip>
        )
    }

    const renderContent = (toggle: Toggle): React.ReactElement => {
        return (
            <Menu>
                {/* These items will be hidden on the larger screens */}
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <SwitchThemeMenuItem onClick={toggle} />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <EnterFullScreenMenuItem onClick={toggle} />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <OpenMenuItem />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <PrintMenuItem onClick={toggle} />
                </div>

                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <GoToPreviousPageMenuItem onClick={toggle} />
                </div>
                <div className="rpv-core__display--block rpv-core__display--hidden-medium">
                    <GoToNextPageMenuItem onClick={toggle} />
                </div>

                <EnterFullScreenMenuItem onClick={toggle} />

                <MenuDivider />
                <RotateForwardMenuItem onClick={toggle} />
                <RotateBackwardMenuItem onClick={toggle} />
                <MenuDivider />
                <SwitchSelectionModeMenuItem mode={SelectionMode.Text} onClick={toggle} />
                <SwitchSelectionModeMenuItem mode={SelectionMode.Hand} onClick={toggle} />
                <MenuDivider />

                <MenuItem
                    onClick={() => {
                        onDownload(fileName || "download", id || "", false, true)
                        toggle()
                    }}>
                    <svg
                        aria-hidden="true"
                        className="rpv-core__icon"
                        focusable="false"
                        height="16px"
                        viewBox="0 0 24 24"
                        width="16px">
                        <path d="M5.5,11.5c-.275,0-.341.159-.146.354l6.292,6.293a.5.5,0,0,0,.709,0l6.311-6.275c.2-.193.13-.353-.145-.355L15.5,11.5V1.5a1,1,0,0,0-1-1h-5a1,1,0,0,0-1,1V11a.5.5,0,0,1-.5.5Z"></path>
                        <path d="M23.5,18.5v4a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-4"></path>
                    </svg>
                    <Tooltip title={"Download a preprocessed version of the PDF"} placement="top">
                        <Typography
                            style={{
                                fontFamily: "Arial",
                                fontSize: 13.3333,
                                padding: "0px 32px 0px 8px",
                            }}>
                            Download as pdf
                        </Typography>
                    </Tooltip>
                </MenuItem>
                {fileSourceUrl && (
                    <MenuItem
                        onClick={() => {
                            window.open(fileSourceUrl, "_blank")
                            toggle()
                        }}>
                        <OpenInNew style={{ width: 16, height: 16 }} />
                        <Typography
                            style={{
                                fontFamily: "Arial",
                                fontSize: 13.3333,
                                padding: "0px 32px 0px 8px",
                            }}>
                            Open on Cloud
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        )
    }

    return (
        <Popover
            ariaControlsSuffix="toolbar-more-actions"
            ariaHasPopup="menu"
            position={portalPosition}
            target={renderTarget}
            content={renderContent}
            offset={PORTAL_OFFSET}
            closeOnClickOutside={true}
            closeOnEscape={true}
        />
    )
}
