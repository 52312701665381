import axios from "axios"

export interface SubscriptionPlanSchema {
    id: string
    name: string
    price: number
    megabytes_of_storage: number
    gpt3_5_tokens: number
    gpt4_0_tokens: number
    pages: number
    translation_characters: number
    workflows_runs: number
    is_custom?: boolean
    payment_link_url?: string
    type: SubscriptionPlanType
    metadata?: string[]
}

export const enum SubscriptionPlanType {
    free = "free",
    pro = "pro",
    pro_plus = "pro_plus",
    enterprise = "enterprise",
}

export type Subscription = {
    id: string
    status: string
    user_id: string
    stripe_customer_id: string
    subscription_plan_id: string
    start_date_utc: string
    end_date_utc?: string
    is_cancelled: boolean
    plan?: SubscriptionPlanSchema
}
export const getSubscription = async (): Promise<Subscription> => {
    try {
        const response = await axios.get(`/subscriptions/active`)

        if (!response.data) {
            throw new Error("Could not get subscription")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get subscription")
    }
}

export const cancelSubscription = async (): Promise<string> => {
    try {
        const response = await axios.post(`/subscriptions/active/cancel`)

        if (response.status !== 200) {
            throw new Error("Could not cancel subscription")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not cancel subscription")
    }
}

export const createCheckoutSession = async (planId: string): Promise<string> => {
    try {
        const response = await axios.post("/subscriptions/create-checkout-session", {
            plan_id: planId,
        })

        console.log(response)

        window.location.href = response.data.url

        return response.data.url
    } catch (error) {
        throw new Error("Failed to create checkout session: " + error)
    }
}

export const renewSubscription = async (): Promise<string> => {
    try {
        const response = await axios.post(`/subscriptions/active/renew`)

        if (response.status !== 200) {
            throw new Error("Could not renew subscription")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not renew subscription")
    }
}

export const getSubscriptionsPlans = async (): Promise<SubscriptionPlanSchema[]> => {
    try {
        const response = await axios.get(`/subscription-plans`)

        if (!response.data) {
            throw new Error("Could not get subscription")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not get subscription")
    }
}
