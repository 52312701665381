import { Grid, Tooltip } from "@mui/material"
import { TokensUsageOptions } from "@/components/Settings/useSettings"
import { ChatAnswerCrownIcon, ChatAnswerIcon, ChatBot } from "@/assets"
import React from "react"
import { styled } from "@mui/material/styles"
import { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"
import { AssistantTypes } from "@/api"

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 800,
    },
})

function ShowAnswerIcon({
    assistant_type,
    tokens_usage_mode,
}: {
    assistant_type: AssistantTypes
    tokens_usage_mode: string
}) {
    return (
        <Grid
            item
            sx={{
                width: "36px",
                paddingBottom: "12px",
            }}
            xs="auto">
            <CustomWidthTooltip
                title={
                    assistant_type === AssistantTypes.BASIC
                        ? "Basic Assistant (for general chat without documents)"
                        : tokens_usage_mode === "highest"
                          ? TokensUsageOptions[2].text
                          : tokens_usage_mode === "increased"
                            ? TokensUsageOptions[1].text
                            : TokensUsageOptions[0].text
                }
                arrow
                placement="top">
                <img
                    style={{ width: "25px" }}
                    src={
                        assistant_type === AssistantTypes.BASIC
                            ? ChatBot
                            : tokens_usage_mode === "highest"
                              ? ChatAnswerCrownIcon
                              : ChatAnswerIcon
                    }
                    alt="Answer"
                />
            </CustomWidthTooltip>
        </Grid>
    )
}

export default ShowAnswerIcon
