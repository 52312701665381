import {
    Avatar,
    Box,
    Grid,
    IconButton,
    Stack,
    TextareaAutosize,
    Tooltip,
    Typography,
    Popper,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from "@mui/material"

import { Lamp, TrashGrey } from "@/assets"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AskResponse, AssistantTypes } from "@/api"
import { stringAvatar, stringToColor } from "@/components/Header/Header"
import { useMsal } from "@azure/msal-react"
import { styles } from "@/components/Answer/styles"
import { useReadLocalStorage } from "usehooks-ts"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import RefreshIcon from "@mui/icons-material/Refresh"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { TokensUsageModes } from "@/api/models"
import { setAssistantType, setTokensUsageMode } from "@/slices/chatSlice"

interface Props {
    message: string
    onSupportingContentClicked?: () => void
    onThoughtProcessClicked?: () => void
    answer?: AskResponse
    onRemoveQA: () => void
    onEditConfirm: (message: string) => void
    onResend: () => void
    isLastMessage: boolean
    isHovering: boolean
}

export const UserChatMessage = ({
    message,
    onSupportingContentClicked,
    onThoughtProcessClicked,
    answer,
    onRemoveQA,
    onEditConfirm,
    onResend,
    isLastMessage,
    isHovering,
}: Props) => {
    const answers = useSelector((state: any) => state.chat.answers)
    const settings = useSelector((state: any) => state.chat)
    const dispatch = useDispatch()
    const isDisabledSupportingContentTab: boolean = !answer?.data_points?.length
    const { accounts } = useMsal()
    let givenName = null
    let familyName = null
    const showTokens = useReadLocalStorage("showTokens")
    const [isEditMode, setIsEditMode] = useState(false)
    const [editedMessage, setEditedMessage] = useState(message)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [previousMode, setPreviousMode] = useState<TokensUsageModes>(settings.selectedTokensUsageMode)
    const [previousAssistantType, setPreviousAssistantType] = useState<AssistantTypes>(settings.assistantType)
    const [menuCloseTimer, setMenuCloseTimer] = useState<NodeJS.Timeout | null>(null)
    const tokenClaims = accounts[0]?.idTokenClaims
    const isLoading = useSelector((state: any) => state.chat.isLoading)
    if (tokenClaims != null) {
        givenName = tokenClaims.given_name ? (tokenClaims.given_name as string).toUpperCase() : null
        familyName = tokenClaims.family_name ? (tokenClaims.family_name as string).toUpperCase() : null
    }
    const tokens_usage_mode = answer?.settings?.overrides?.tokens_usage_mode
    const assistant_type = answer?.settings?.overrides?.assistant_type

    useEffect(() => {
        setEditedMessage(message)
    }, [message])

    const onCopy = () => {
        const answerText = answer?.answer

        if (!answerText) {
            return
        }

        const answerWithoutCitations = answerText.replace(/(\[.*?\])/g, "")
        const answerWithoutFollowUpQuestionsAndCitations = answerWithoutCitations.replace(/(<<.*?>>)/g, "")

        navigator.clipboard.writeText(answerWithoutFollowUpQuestionsAndCitations.trim())
    }

    const handleRegenerateClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        setPreviousMode(settings.selectedTokensUsageMode)
        setPreviousAssistantType(settings.assistantType)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleMouseLeave = () => {
        const timer = setTimeout(() => {
            handleMenuClose()
        }, 800)
        setMenuCloseTimer(timer)
    }

    const handleMouseEnter = () => {
        if (menuCloseTimer) {
            clearTimeout(menuCloseTimer)
            setMenuCloseTimer(null)
        }
    }

    useEffect(() => {
        return () => {
            if (menuCloseTimer) {
                clearTimeout(menuCloseTimer)
            }
        }
    }, [menuCloseTimer])

    const handleQualitySelect = (mode: TokensUsageModes | undefined, assistant: AssistantTypes | undefined) => {
        if (mode !== undefined) {
            dispatch(setTokensUsageMode(mode))
        }
        if (assistant !== undefined) {
            dispatch(setAssistantType(assistant))
        }
        handleMenuClose()
        onResend()
        // Reset to previous mode after regeneration
        setTimeout(() => {
            console.log("Resetting to previous mode after regeneration")
            if (mode !== undefined) {
                dispatch(setTokensUsageMode(previousMode))
            }
            if (assistant !== undefined) {
                dispatch(setAssistantType(previousAssistantType))
            }
        }, 5000)
    }

    const menuItems = [
        {
            text: "Optimal Efficiency",
            tooltip: "Balanced performance and cost",
            onClick: () => handleQualitySelect(TokensUsageModes.Standard, AssistantTypes.DOC_ASSISTANT),
            isSelected:
                assistant_type === AssistantTypes.DOC_ASSISTANT && tokens_usage_mode === TokensUsageModes.Standard,
        },
        {
            text: "High Performance",
            tooltip: "Enhanced quality with moderate cost",
            onClick: () => handleQualitySelect(TokensUsageModes.Increased, AssistantTypes.DOC_ASSISTANT),
            isSelected:
                assistant_type === AssistantTypes.DOC_ASSISTANT && tokens_usage_mode === TokensUsageModes.Increased,
        },
        {
            text: "Superior Quality",
            tooltip: "Maximum quality at higher cost and slower speed",
            onClick: () => handleQualitySelect(TokensUsageModes.Highest, AssistantTypes.DOC_ASSISTANT),
            isSelected:
                assistant_type === AssistantTypes.DOC_ASSISTANT && tokens_usage_mode === TokensUsageModes.Highest,
        },
        {
            text: "Without documents",
            tooltip: "General chat without your documents",
            onClick: () => handleQualitySelect(undefined, AssistantTypes.BASIC),
            isSelected: assistant_type === AssistantTypes.BASIC,
        },
    ]

    return (
        <Grid
            className="notranslate"
            container
            sx={{
                padding: "20px 20px 10px 0",
                position: "relative",
                background: "#fff",
                alignItems: "flex-start",
                pr: "20px",
                flexWrap: "nowrap",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
            }}>
            <Grid
                item
                sx={{
                    margin: "0 20px",
                    marginTop: "10px",
                }}
                xs="auto">
                <Avatar
                    {...stringAvatar(`${givenName} ${familyName}`)}
                    sx={{
                        width: "5vw",
                        minWidth: "24px",
                        maxWidth: "34px",
                        height: "5vw",
                        minHeight: "24px",
                        maxHeight: "34px",
                        marginTop: "-5px",
                        marginBottom: "5px",
                        fontSize: "17px",
                        bgcolor: stringToColor(`${givenName} ${familyName}`),
                    }}
                />
            </Grid>
            <Grid item xs={11} sx={{ ...styles.answerText }}>
                {isEditMode ? (
                    <>
                        <TextareaAutosize
                            value={editedMessage}
                            onChange={e => setEditedMessage(e.target.value)}
                            minRows={1}
                            maxRows={10}
                            style={{
                                width: "105%",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                padding: "10px",
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontFamily: "Segoe UI",
                            }}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <IconButton onClick={() => onEditConfirm(editedMessage)}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setIsEditMode(false)
                                    setEditedMessage(message)
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>{message}</Typography>
                    </Box>
                )}
            </Grid>
            {!!answers.length && (
                <>
                    <Grid item xs="auto" sx={{ marginRight: "0px", marginTop: "-3px" }}>
                        <Stack direction="row" spacing={0.4} justifyContent="flex-end">
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={() => setIsEditMode(true)}
                                    style={{
                                        visibility:
                                            isLastMessage && !isEditMode && isHovering && !isLoading
                                                ? "visible"
                                                : "hidden",
                                    }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            {isLastMessage && (
                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={onCopy}
                                        style={{
                                            visibility: isHovering && !isLoading ? "visible" : "hidden",
                                        }}>
                                        <ContentCopyIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Box
                                sx={{
                                    position: "relative",
                                    display: "inline-block",
                                }}>
                                <Tooltip title={"Regenerate"}>
                                    <IconButton
                                        onClick={handleRegenerateClick}
                                        style={{
                                            visibility:
                                                isLastMessage && isHovering && !isLoading ? "visible" : "hidden",
                                        }}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                                <Popper
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    placement="bottom-end"
                                    sx={{ zIndex: 1300 }}>
                                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                        <Paper
                                            onMouseLeave={handleMouseLeave}
                                            onMouseEnter={handleMouseEnter}
                                            sx={{
                                                boxShadow:
                                                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                                            }}>
                                            <MenuList>
                                                {menuItems.map((item, index) => (
                                                    <Tooltip key={index} title={item.tooltip} placement="left">
                                                        <MenuItem
                                                            onClick={item.onClick}
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "8px",
                                                            }}>
                                                            <Box
                                                                sx={{
                                                                    width: "15px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}>
                                                                {item.isSelected && (
                                                                    <CheckIcon
                                                                        style={{
                                                                            color: "#4e7cbf",
                                                                            fontSize: "1rem",
                                                                            marginLeft: "-3px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </Box>
                                                            <span>{item.text}</span>
                                                        </MenuItem>
                                                    </Tooltip>
                                                ))}
                                            </MenuList>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                            </Box>
                            {!isLastMessage && (
                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={onCopy}
                                        style={{
                                            visibility: isHovering && !isLoading ? "visible" : "hidden",
                                        }}>
                                        <ContentCopyIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!!showTokens && (
                                <IconButton
                                    style={{
                                        maxWidth: "42px",
                                    }}
                                    onClick={
                                        isDisabledSupportingContentTab
                                            ? onThoughtProcessClicked
                                            : onSupportingContentClicked
                                    }>
                                    <img
                                        style={{
                                            minWidth: "10px",
                                            maxWidth: "24px",
                                            visibility: isHovering ? "visible" : "hidden",
                                        }}
                                        src={Lamp}
                                        alt="Lamp"
                                    />
                                </IconButton>
                            )}
                            {isHovering && !isLoading ? (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={onRemoveQA}
                                        style={{
                                            maxWidth: "42px",
                                        }}>
                                        <img
                                            style={{ minWidth: "10px", maxWidth: "27px" }}
                                            src={TrashGrey}
                                            alt="Trash"
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {isLoading && (
                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={onCopy}
                                        style={{
                                            visibility: isHovering ? "visible" : "hidden",
                                        }}>
                                        <ContentCopyIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Grid>
                </>
            )}
        </Grid>
    )
}
