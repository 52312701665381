// AnswerParser.tsx
import { renderToStaticMarkup } from "react-dom/server"
import { citationColors } from "@/theme"
import { processFileName } from "@/pages/documents/Documents"
import { marked } from "marked"
import { DownArrow } from "@/assets"
import React from "react"
import { Button, Tooltip } from "@mui/material"
import { t } from "i18next"

export type HtmlParsedAnswer = {
    fragments: AnswerFragment[]
    citations: string[]
    followupQuestions: string[]
}

export enum AnswerFragmentType {
    Citation = "citation",
    Chart = "chart",
    Text = "text",
    Image = "image",
}

export type AnswerFragment = {
    text: any
    type: AnswerFragmentType
}

function decodeHtmlEntities(str: string) {
    const doc = new DOMParser().parseFromString(str, "text/html")
    return doc.documentElement.textContent
}

const emptySymbol = "&nbsp;"
const citationsRegex = /\[([^[:\n]+\.[a-z]{1,6}:[a-f0-9]{24}:\d+:\d\.\d+)]/g
const imageCitationsRegex = /\[([^[:\n]+\.pdf:[a-f0-9]{24}:\d+;\d+)]/g
const chartRegex = /(```\s*mermaid[\s\S]*?```)/g
const htmlPageRegex = /<!DOCTYPE html>[\s\S]*<\/html>/g

function convertOrderedListToNumberedUl(html: string): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, "text/html")
    const olElements = doc.querySelectorAll("ol")

    olElements.forEach(ol => {
        const listItems = ol.querySelectorAll(":scope > li")
        let containsImageCitation = false

        listItems.forEach(li => {
            if (imageCitationsRegex.test(li.innerHTML)) {
                containsImageCitation = true
            }
        })

        if (containsImageCitation) {
            let numberedText = ""
            listItems.forEach((li, index) => {
                const textContent = li.textContent?.trim() ?? ""
                numberedText += `${index + 1}. ${textContent}\n`
            })

            const divElement = doc.createElement("div")
            divElement.innerHTML = `<span style="padding-top: 0.9em; white-space: pre-line;">${numberedText.trim()}</span>`
            ol.replaceWith(divElement)
        }
    })

    return doc.body.innerHTML
}

function prepareAnswer(answer: string) {
    let newAnswer = ""
    let previousLine = ""
    let firstLineOfTable = ""
    let isTableProcessed = false

    // Remove the redundant ```markdown ``` wrapper
    answer = answer.replace(/```markdown\s*([\s\S]*?)```/gs, "$1")

    answer.split("\n").forEach(line => {
        let newLine = line

        const isTableRow = line.startsWith("|")

        if (previousLine.startsWith("|") && !isTableRow) {
            newLine = `\n${line}`
        }

        // if table has no header row,then we need add empty header row to make it work with markdown
        if (isTableRow && firstLineOfTable !== "" && !isTableProcessed) {
            if (!line.includes("----")) {
                const numberColumns = firstLineOfTable.split("|").length - 2
                //remove previous line
                newAnswer = newAnswer.slice(0, -previousLine.length - 1)

                newAnswer += "|"
                for (let i = 0; i < numberColumns; i++) {
                    newAnswer += " |"
                }

                newAnswer += "\n|"
                for (let i = 0; i < numberColumns; i++) {
                    newAnswer += "----|"
                }

                newAnswer += `\n${previousLine}\n`
            }

            isTableProcessed = true
        }

        if (isTableRow && firstLineOfTable === "") {
            firstLineOfTable = line
        }

        if (!isTableRow && isTableProcessed) {
            firstLineOfTable = ""
            isTableProcessed = false
        }

        if (newLine === "") {
            newAnswer += `${emptySymbol}\n`
        } else {
            newAnswer += `${newLine}\n`
        }

        previousLine = line
    })

    newAnswer = newAnswer.replace(/^\n+|\n+$/g, "").trim()
    newAnswer = newAnswer.replace("mermaid ", "mermaid\n")

    //Remove body styles if they are present in format body {... }
    newAnswer = newAnswer.replace(/body\s*{[^}]*}/g, "")

    //Remove empty symbols from the end of the answer
    while (newAnswer.endsWith(`${emptySymbol}\n`)) {
        newAnswer = newAnswer.slice(0, -(`${emptySymbol}\n`.length + 1))
    }

    while (newAnswer.includes("&nbsp;\n```")) {
        newAnswer = newAnswer.replace("&nbsp;\n```", "```")
    }

    let html = ""

    html = String(marked.parse(answer))

    const mermaidBlocks = html.match(/<code class="language-mermaid">([\s\S]*?)<\/code>/g) || []

    mermaidBlocks.forEach(block => {
        const blockContent = block
            .replace(/<code class="language-mermaid">/g, "```mermaid\n")
            .replace(/<\/code>/g, "\n```")
        // .replace(/&quot;/g, '"')
        // .replace(/&ndash;/g, "-")
        // .replace(/&mdash;/g, "--")
        // .replace(/&lt;/g, "<")
        // .replace(/&gt;/g, ">")
        // .replace(/&amp;/g, "&")
        // .replace(/&nbsp;/g, " ")
        // @ts-ignore
        html = html.replace(block, decodeHtmlEntities(blockContent))
    })

    const anyCodeBlocks = html.match(/<code class="language-[^"]*">([\s\S]*?)<\/code>/g) || []
    anyCodeBlocks.forEach(block => {
        // @ts-ignore
        html = html.replace(block, decodeHtmlEntities(block))
    })

    // html = decodeHtmlEntities(html)
    // remove <code> and </code> tags for "<code class="language-html">" blocks
    // html = html.replace(/<code class="language-html">/g, "").replace(/<\/code>/g, "")
    // console.log(html)
    html = html.replace(/<p>/g, "").replace(/<\/p>/g, "").trim()
    html = html.replace(/&lt;/g, "<").replace(/&gt;/g, ">")

    if (html.includes("<ol>")) {
        html = convertOrderedListToNumberedUl(html)
    }

    return html
}

export async function parseAnswerToHtml(
    answer: string,
    onCitationClicked?: (citationFilePath: string) => void
): Promise<HtmlParsedAnswer> {
    // console.log("Parsing answer to HTML")
    if (!answer) {
        return {
            fragments: [],
            citations: [],
            followupQuestions: [],
        }
    }
    const followupQuestions: string[] = []

    const answerWithoutFollowUpQuestions = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        followupQuestions.push(content)

        return ""
    })

    //remove all fonts styles from the answer
    const answerWithoutCustomFonts = answerWithoutFollowUpQuestions
        .replace(/font-family:.*?;/g, "")
        .replace(/@font-face\s*{[^}]*}/g, "")

    const answerWithMarkdown = answer.includes("google.visualization")
        ? answerWithoutCustomFonts.replace("```html", "").replace("```", "")
        : prepareAnswer(answerWithoutCustomFonts)

    const citations: string[] = []
    const citationMap = new Map<string, number>()

    const splitRegex = `${citationsRegex.source}|${imageCitationsRegex.source}|${chartRegex.source}|${htmlPageRegex}`
    const parts = answerWithMarkdown.trim().split(new RegExp(splitRegex, "g"))

    const fragments: AnswerFragment[] = []

    const addFragmentToFragments = (text: string | JSX.Element, type: AnswerFragmentType) => {
        const previousFragmentType = fragments.length > 0 ? fragments[fragments.length - 1].type : null
        if (
            previousFragmentType != null &&
            previousFragmentType !== AnswerFragmentType.Chart &&
            type !== AnswerFragmentType.Chart &&
            previousFragmentType !== AnswerFragmentType.Image &&
            type !== AnswerFragmentType.Image
        ) {
            fragments[fragments.length - 1].text += text
        } else if (typeof text == "string" && text.trim() !== emptySymbol) {
            fragments.push({ text, type })
        } else if (typeof text !== "string") {
            fragments.push({ text, type })
        }
    }

    const citations_: string[] = []
    for (const part of parts) {
        if (!part || part.trim() === "") continue
        const is_citation = `[${part}]`.match(citationsRegex) != null
        if (is_citation && !citations_.includes(part)) {
            citations_.push(part)
        }
    }

    // Function to add export button to tables
    const getExportButtonToHtml = (): string => {
        return renderToStaticMarkup(
            <Tooltip title="Download table as CSV">
                <Button
                    className="notranslate"
                    startIcon={
                        <div
                            style={{
                                width: "22px",
                                height: "22px",
                                backgroundColor: "#4e7cbf",
                                WebkitMaskImage: `url(${DownArrow})`,
                                maskImage: `url(${DownArrow})`,
                                WebkitMaskSize: "cover",
                                maskSize: "cover",
                                marginBottom: "3px",
                                marginRight: "8px",
                            }}
                        />
                    }
                    style={{
                        padding: "0px",
                        color: "#4e7cbf",
                        position: "relative",
                        boxSizing: "border-box",
                        backgroundColor: "transparent",
                        display: "flex",
                        height: "24px",
                        lineHeight: "24px",
                        fontSize: "16px",
                        cursor: "pointer",
                        border: 0,
                        margin: 0,
                        marginRight: "auto",
                        marginTop: "8px",
                        borderRadius: "4px",
                    }}>
                    {t("Download table as CSV")}
                </Button>
            </Tooltip>
        )
    }

    const tablesFromHtml = answerWithMarkdown.match(/<table[\s\S]*?<\/table>/g) || []

    let tableIndex = -1

    // Function to split text fragment by tables and add export button
    const processTextFragment = (text: string) => {
        const tableStartTag = "<table"
        const tableEndTag = "</table>"
        let lastIndex = 0
        let buffer = ""
        let insideTable = false

        for (let i = 0; i < text.length; i++) {
            if (!insideTable && text.substring(i, i + tableStartTag.length) === tableStartTag) {
                // Add text before the table
                if (lastIndex < i) {
                    addFragmentToFragments(text.substring(lastIndex, i), AnswerFragmentType.Text)
                    lastIndex = i // Update lastIndex to the start of the table
                }
                insideTable = true
            }

            buffer += text[i]

            if (buffer.endsWith(tableEndTag)) {
                tableIndex += 1
                // Complete table detected
                let exportButtonHtml = getExportButtonToHtml()
                const tableHtml = tablesFromHtml[tableIndex]

                // Add data attribute to export button to identify the table
                exportButtonHtml = exportButtonHtml.replace(
                    "<Button",
                    `<Button data-table-index="${tableIndex}" data-table-html="${encodeURIComponent(tableHtml)}"`
                )

                if (buffer.includes(tableHtml)) {
                    addFragmentToFragments(`${tableHtml}${exportButtonHtml}`, AnswerFragmentType.Text)
                } else {
                    addFragmentToFragments(`</table>\n${exportButtonHtml}`, AnswerFragmentType.Text)
                }

                insideTable = false
                lastIndex = i + 1
                buffer = "" // Clear buffer after processing the table
            }
        }

        // Add remaining text after the last table or if no complete table was found
        if (lastIndex < text.length) {
            addFragmentToFragments(text.substring(lastIndex), AnswerFragmentType.Text)
        }
    }

    for (const part of parts) {
        if (!part || part.trim() === "") continue

        const is_citation = `[${part}]`.match(citationsRegex) != null
        const is_image_citation = `[${part}]`.match(imageCitationsRegex) != null
        const is_chart = part.match(chartRegex) != null || part.includes("google.visualization")

        const is_last = parts.indexOf(part) === parts.length - 1

        let processedPart = part

        if (is_last) {
            processedPart = processedPart.trim()
            while (processedPart.endsWith(emptySymbol)) {
                processedPart = processedPart.trim()
                processedPart = processedPart.slice(0, -(emptySymbol.length + 1))
            }
        }

        if (is_citation && onCitationClicked) {
            let citationIndex: number
            if (citationMap.has(processedPart)) {
                citationIndex = citationMap.get(processedPart)!
            } else {
                citations.push(processedPart)
                citationIndex = citations.length
                citationMap.set(processedPart, citationIndex)
            }

            const citationInfo = processedPart.split(":")

            const citationMarkup = renderToStaticMarkup(
                <a className="supContainer" onClick={() => onCitationClicked(processedPart)}>
                    <sup
                        title={processFileName(citationInfo[0])}
                        style={{ backgroundColor: citationColors[(citationIndex - 1) % citationColors.length] }}
                        data-id={citationInfo[1]}
                        data-page={citationInfo[2]}>
                        {citationIndex}
                    </sup>
                </a>
            )
            addFragmentToFragments(citationMarkup, AnswerFragmentType.Citation)
        } else if (is_image_citation) {
            const [fileName, fileId, pageAndFigure] = processedPart.split(":")
            const [page, figureId] = pageAndFigure.split(";")

            const imageURL = `/files/get_image/${fileId}-fig-${figureId}.webp?file_id=${fileId}&thumbnail=true`
            addFragmentToFragments(imageURL, AnswerFragmentType.Image)

            // Add a citation for images
            let simpleCitation = `${fileName}:${fileId}:${page}`
            let existingKey = null

            for (const key of citations_) {
                if (key.startsWith(simpleCitation) && key !== simpleCitation) {
                    existingKey = key
                    break
                }
            }

            if (existingKey) {
                simpleCitation = existingKey
            }

            if (!citationMap.has(simpleCitation)) {
                citations.push(simpleCitation)
                citationMap.set(simpleCitation, citations.length)
            }

            const citationIndex = citationMap.get(simpleCitation)!

            const citationMarkup = renderToStaticMarkup(
                <a className="supContainer" onClick={() => onCitationClicked && onCitationClicked(simpleCitation)}>
                    <sup
                        title={processFileName(fileName)}
                        style={{ backgroundColor: citationColors[(citationIndex - 1) % citationColors.length] }}
                        data-id={fileId}
                        data-page={page}>
                        {citationIndex}
                    </sup>
                </a>
            )
            addFragmentToFragments(citationMarkup, AnswerFragmentType.Citation)
        } else if (is_chart) {
            addFragmentToFragments(processedPart, AnswerFragmentType.Chart)
        } else {
            processTextFragment(processedPart)
        }
    }
    return {
        fragments,
        citations,
        followupQuestions,
    }
}
