import React, { ReactElement, useEffect, useRef, useState } from "react"

import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material"
import { setActiveAnalysisPanelTab } from "@/slices/chatSlice"
import { useDispatch, useSelector } from "react-redux"
import { AnalysisPanelTabs } from "@/components/AnalysisPanel"
import { DocumentLoadEvent, OpenFile, PageChangeEvent, SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import { defaultLayoutPlugin, SidebarTab, ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/default-layout"
import { RenderSearchProps, searchPlugin, SearchProps } from "@react-pdf-viewer/search"
import { RenderZoomInProps, RenderZoomOutProps, ZoomInIcon, ZoomOutIcon, zoomPlugin } from "@react-pdf-viewer/zoom"
import "@react-pdf-viewer/full-screen/lib/styles/index.css"
import { CustomMoreActionsPopover } from "@/components/CitationPDFViewer/MoreActionsPopover"
import ClearIcon from "@mui/icons-material/Clear"
import styles from "./CitationPDFViewer.module.css"
import Fuse from "fuse.js"
import { processFileName } from "@/pages/documents/Documents"
import TranslateIcon from "@mui/icons-material/Translate"
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file"
import { DownArrow } from "@/assets"
import theme from "@/theme"
import { t } from "i18next"
import { handleTranslatePDF, targetLanguageReadable } from "./PDFTranslation"
import { getSavedLanguage } from "@/i18n"
import { ChevronDown, ChevronUp } from "lucide-react"
import { generatePath } from "react-router-dom"
import { RoutesPath } from "@/router/config"
import { CustomLink } from "@/components/CustomLink/CustomLink"
import { onDownload } from "@/utils/download"

export interface IHighlightArray {
    simple: string[]
    semantic: string[]
    answer: string[]
}

interface ICitationPDFViewerProps {
    pdfBlob: Blob
    fileName: string
    id?: string
    large?: boolean
    initialPage: number
    highlightArray?: IHighlightArray
    handleClose?: () => void | undefined
    autoHeight?: boolean | undefined
    language?: string
}

interface CustomSearchProps {
    Search: React.ComponentType<SearchProps>
}

export const enum CitationsHighlightMode {
    ANSWER_PRIORITY = "answer_priority",
    SEMANTIC_PRIORITY = "semantic_priority",
    SIMPLE_PRIORITY = "simple_priority",
    ONLY_ANSWER = "only_answer",
    ONLY_SEMANTIC = "only_semantic",
    ONLY_SIMPLE = "only_simple",
}

function findFuzzyMatchesInPdfContent(highlights: any[], content: string, threshold = 0.4) {
    // Split the content into searchable chunks, e.g., sentences or paragraphs
    const contentChunks = content.match(/[^.!?]+([.!?]+|$)/g) || []
    const numbers = content.match(/[$,.\d]{3,20}/g) || []
    const numbersTrimmed = numbers.map(item => item.replace(/[,.]+$/g, "").trim())

    const fuseOptions = {
        includeScore: true,
        findAllMatches: true,
        threshold: threshold, // Lower means more strict matching
    }

    const contentChunksWithNumbers = [...contentChunks, ...numbersTrimmed]

    const fuse = new Fuse(contentChunksWithNumbers, fuseOptions)

    let matches: string[] = []

    highlights.forEach(highlight => {
        // Perform the fuzzy search
        const searchResults = fuse.search(highlight)

        // Extract matched chunks from the content
        searchResults.forEach(({ item }) => {
            matches.push(item)
        })
    })

    // Remove duplicates
    matches = [...new Set(matches)]

    return matches
}

function processHighlightsBasedOnPriority(
    highlightPriorities: string[][],
    CurrentPageTextString: string,
    thresholds: number[]
): string[] {
    let result: string[] = []
    for (let i = 0; i < highlightPriorities.length; i++) {
        result = findFuzzyMatchesInPdfContent(highlightPriorities[i], CurrentPageTextString, thresholds[i])
        if (result.length > 0) break
    }
    return result
}

function getHighlights(
    highlightArray: {
        simple: string[]
        semantic: string[]
        answer: string[]
    },
    CurrentPageTextString: string,
    citationsHighlightMode: CitationsHighlightMode
): string[] {
    let toHighlight: string[] = []
    const allArrays = [...highlightArray.simple, ...highlightArray.semantic, ...highlightArray.answer]

    switch (citationsHighlightMode) {
        case CitationsHighlightMode.ANSWER_PRIORITY: {
            toHighlight = processHighlightsBasedOnPriority(
                [highlightArray.answer, allArrays, allArrays, allArrays],
                CurrentPageTextString,
                [0.4, 0.2, 0.4, 0.6]
            )
            break
        }
        case CitationsHighlightMode.SEMANTIC_PRIORITY: {
            toHighlight = processHighlightsBasedOnPriority(
                [highlightArray.semantic, allArrays, allArrays, allArrays],
                CurrentPageTextString,
                [0.2, 0.2, 0.4, 0.6]
            )
            break
        }
        case CitationsHighlightMode.SIMPLE_PRIORITY: {
            toHighlight = processHighlightsBasedOnPriority(
                [highlightArray.simple, allArrays, allArrays, allArrays],
                CurrentPageTextString,
                [0.2, 0.2, 0.4, 0.6]
            )
            break
        }

        case CitationsHighlightMode.ONLY_ANSWER:
            toHighlight = processHighlightsBasedOnPriority(
                [highlightArray.answer, highlightArray.answer, highlightArray.answer],
                CurrentPageTextString,
                [0.2, 0.4, 0.6]
            )
            break
        case CitationsHighlightMode.ONLY_SEMANTIC:
            toHighlight = processHighlightsBasedOnPriority(
                [highlightArray.semantic, highlightArray.semantic, highlightArray.semantic],
                CurrentPageTextString,
                [0.2, 0.4, 0.6]
            )
            break
        case CitationsHighlightMode.ONLY_SIMPLE:
            toHighlight = processHighlightsBasedOnPriority(
                [highlightArray.simple, highlightArray.simple, highlightArray.simple],
                CurrentPageTextString,
                [0.2, 0.4, 0.6]
            )
            break
        default:
            break
    }

    // console.log("toHighlight", toHighlight)
    return toHighlight
}

export const CitationPDFViewer: React.FC<ICitationPDFViewerProps> = ({
    highlightArray,
    initialPage,
    pdfBlob,
    fileName,
    id,
    large,
    handleClose,
    autoHeight,
}) => {
    const [page, setPage] = useState(initialPage - 1)
    const dispatch = useDispatch()
    const [fileUrl, setFileUrl] = useState<string>(URL.createObjectURL(pdfBlob))
    const originalFileUrl = URL.createObjectURL(pdfBlob)
    const citationsHighlightMode = useSelector((state: any) => state.chat.citationsHighlightMode)

    const [isTranslating, setIsTranslating] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState("original")

    const currentPage = useRef(0)
    const originalPage = useRef(0)

    const [anchorEl, setAnchorEl] = useState(null)

    const [totalPages, setTotalPages] = useState(0)

    const [pdfDocument, setPdfDocument] = useState(null)
    const targetLanguageCode = getSavedLanguage()

    const [fileNameCurrent, setFileNameCurrent] = useState<string>(fileName)
    const [isFullFileTranslated, setIsFullFileTranslated] = useState(false)

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            return fileNameCurrent
        },
    })
    const { Download } = getFilePluginInstance
    const handleTranslateMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore
        setAnchorEl(event.currentTarget)
    }

    const handleTranslateMenuClose = () => {
        setAnchorEl(null)
    }

    const getTotalPages = async () => {
        if (pdfDocument) {
            // @ts-ignore
            return pdfDocument.numPages
        }
        return 0
    }
    const handleTranslate = handleTranslatePDF({
        id,
        currentPage,
        setIsTranslating,
        currentLanguage,
        setFileUrl,
        originalFileUrl,
        setFileNameCurrent,
        fileName,
        setCurrentLanguage,
        setPage,
        originalPage,
        setIsFullFileTranslated,
        totalPages,
        targetLanguageCode,
        targetLanguageReadable,
    })
    const handleMenuItemClick = (action: "page" | "full") => {
        handleTranslate(action)
        handleTranslateMenuClose()
    }
    useEffect(() => {
        if (pdfDocument) {
            getTotalPages().then(pages => {
                setTotalPages(pages)
            })
        }
    }, [pdfDocument])

    if (!fileUrl) {
        dispatch(setActiveAnalysisPanelTab(AnalysisPanelTabs.SupportingContentTab))

        return null
    }

    const processHighlights = (highlights: string[]): string[] => {
        if (!highlights) {
            return []
        }
        const processedHighlights: string[] = []
        for (const item of highlights) {
            // Remove HTML tags
            const sentence = item.replace(/<[^>]*>/g, "")
            // Skip too short
            if (sentence.length > 4) {
                processedHighlights.push(sentence)
            }
        }
        return processedHighlights
    }

    const processAllHighlightTypes = (highlightArray: any) => {
        return {
            simple: processHighlights(highlightArray.simple),
            semantic: processHighlights(highlightArray.semantic),
            answer: processHighlights(highlightArray.answer),
        }
    }

    const highlightArrayRes = processAllHighlightTypes(highlightArray)

    const searchPluginInstance = searchPlugin({ keyword: [] })

    const { highlight, setTargetPages } = searchPluginInstance

    const handleDocumentLoad = async (e: DocumentLoadEvent) => {
        // @ts-ignore
        setPdfDocument(e.doc)
        if (highlightArrayRes.simple.length + highlightArrayRes.semantic.length + highlightArrayRes.answer.length > 0) {
            const numberOfPages = e.doc.numPages
            const currentPageNumber = Math.min(page + 1, numberOfPages)
            const CurrentPage = await e.doc.getPage(currentPageNumber)
            const CurrentPageText = await CurrentPage.getTextContent()

            // console.log("CurrentPageText", CurrentPageText)
            const CurrentPageTextString = CurrentPageText.items.map(item => item.str).join("")
            // console.log("CurrentPageTextString", CurrentPageTextString)
            const toHighlight = getHighlights(highlightArrayRes, CurrentPageTextString, citationsHighlightMode)
            // console.log("toHighlight", toHighlight)
            if (toHighlight.length > 0) {
                setTargetPages(targetPage => targetPage.pageIndex === currentPageNumber - 1)
            }
            if (toHighlight.length > 0) {
                highlight([...toHighlight])
            }
        }

        setTimeout(
            () => {
                zoomTo(SpecialZoomLevel.PageWidth)
            },
            Math.min(1.5 * initialPage + 500, 1000)
        )
    }

    const handlePageChange = async (e: PageChangeEvent) => {
        // Update the current page whenever it changes
        currentPage.current = e.currentPage
    }

    function CustomSearch({ Search }: Readonly<CustomSearchProps>) {
        return (
            <Search>
                {(renderSearchProps: RenderSearchProps) => {
                    const [searchTerm, setSearchTerm] = useState("")

                    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                        const newSearchTerm = e.target.value
                        setSearchTerm(newSearchTerm)
                        if (newSearchTerm === "") {
                            renderSearchProps.clearKeyword()
                        }
                        setTargetPages(() => true) // reset target pages
                        renderSearchProps.setKeyword(newSearchTerm)
                    }

                    useEffect(() => {
                        const delayDebounce = setTimeout(() => {
                            if (searchTerm) {
                                renderSearchProps.search()
                            }
                        }, 150)
                        return () => clearTimeout(delayDebounce)
                    }, [searchTerm])

                    const [isPrevHovered, setIsPrevHovered] = useState(false)
                    const [isNextHovered, setIsNextHovered] = useState(false)

                    const buttonStyle = {
                        border: "none",
                        borderRadius: "4px",
                        color: "var(--rpv-core__minimal-button-color)",
                        cursor: "pointer",
                        height: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative" as "relative",
                        transition: "background-color 0.3s, color 0.3s",
                    }

                    const hoveredStyle = {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        color: "black",
                    }

                    return (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 1,
                                    paddingLeft: "0.1rem",
                                    paddingRight: "0.1rem",
                                    justifyContent: "center",
                                }}>
                                <input
                                    placeholder="Enter to search"
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                    style={{
                                        height: "2rem",
                                        display: "flex",
                                        flex: 1,
                                        minWidth: "3rem",
                                        maxWidth: "100%",
                                        width: "100%",
                                        boxSizing: "border-box",
                                        borderRadius: "4px",
                                        padding: "0.35rem",
                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                    }}
                                />
                            </div>
                            {!renderSearchProps.keyword && (
                                <div
                                    style={{
                                        width: "0.1rem",
                                    }}
                                />
                            )}
                            {renderSearchProps.keyword && renderSearchProps.numberOfMatches === 0 && (
                                <Box sx={{ lineHeight: "1.1" }}>
                                    <Typography
                                        variant="inherit"
                                        fontSize={14}
                                        sx={{ marginLeft: "0.1rem", marginRight: "0.1rem" }}>
                                        Not
                                    </Typography>
                                    <Typography
                                        variant="inherit"
                                        fontSize={14}
                                        sx={{ marginLeft: "0.1rem", marginRight: "0.1rem" }}>
                                        found
                                    </Typography>
                                </Box>
                            )}
                            {renderSearchProps.keyword && renderSearchProps.numberOfMatches > 0 && (
                                <>
                                    <Typography variant="inherit" fontSize={14} sx={{ marginLeft: "0.1rem" }}>
                                        {renderSearchProps.currentMatch}/{renderSearchProps.numberOfMatches}
                                    </Typography>

                                    <div>
                                        <div>
                                            <button
                                                style={{
                                                    ...buttonStyle,
                                                    ...(isPrevHovered ? hoveredStyle : {}),
                                                }}
                                                onClick={renderSearchProps.jumpToPreviousMatch}
                                                onMouseEnter={() => setIsPrevHovered(true)}
                                                onMouseLeave={() => setIsPrevHovered(false)}>
                                                <Tooltip title="Previous match">
                                                    <ChevronUp size={16} />
                                                </Tooltip>
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                style={{
                                                    ...buttonStyle,
                                                    ...(isNextHovered ? hoveredStyle : {}),
                                                }}
                                                onClick={renderSearchProps.jumpToNextMatch}
                                                onMouseEnter={() => setIsNextHovered(true)}
                                                onMouseLeave={() => setIsNextHovered(false)}>
                                                <Tooltip title="Next match">
                                                    <ChevronDown size={16} />
                                                </Tooltip>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )
                }}
            </Search>
        )
    }

    function translateComponent() {
        const isSmallScreen = useMediaQuery(theme.breakpoints.down(large ? 500 : 1000))

        const getButtonText = (text: string) => {
            if (isSmallScreen) {
                return null
            }
            return text
        }
        const buttonStyleWithText = {
            minWidth: "50px",
            padding: "5px",
        }

        const buttonStyleWithoutText = {
            minWidth: "30px", // Adjust the width as needed
            padding: "0px", // Adjust the padding as needed
        }
        return (
            <>
                {totalPages === 1 ? (
                    <Tooltip
                        title={
                            currentLanguage === "original"
                                ? isTranslating
                                    ? `${t("Translating")}...`
                                    : t("Translate")
                                : t("Original")
                        }>
                        <span>
                            <Button
                                className="notranslate"
                                onClick={() => handleTranslate("full")}
                                disabled={isTranslating}
                                startIcon={isTranslating ? <CircularProgress size={20} /> : <TranslateIcon />}
                                sx={isSmallScreen ? buttonStyleWithoutText : buttonStyleWithText}>
                                {getButtonText(
                                    currentLanguage === "original"
                                        ? isTranslating
                                            ? `${t("Translating")}...`
                                            : t("Translate")
                                        : t("Original")
                                )}
                            </Button>
                        </span>
                    </Tooltip>
                ) : (
                    <>
                        {currentLanguage === "original" ? (
                            <Tooltip title={isTranslating ? `${t("Translating")}...` : t("Translate")}>
                                <span>
                                    <Button
                                        className="notranslate"
                                        onClick={handleTranslateMenuOpen}
                                        disabled={isTranslating}
                                        startIcon={isTranslating ? <CircularProgress size={20} /> : <TranslateIcon />}
                                        sx={isSmallScreen ? buttonStyleWithoutText : buttonStyleWithText}>
                                        {getButtonText(isTranslating ? `${t("Translating")}...` : t("Translate"))}
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip title={t("Original")}>
                                <span>
                                    <Button
                                        className="notranslate"
                                        onClick={() => handleTranslate("page")}
                                        sx={isSmallScreen ? buttonStyleWithoutText : buttonStyleWithText}
                                        startIcon={<TranslateIcon />}>
                                        {getButtonText(t("Original"))}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                        <Menu
                            className="notranslate"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleTranslateMenuClose}>
                            <MenuItem onClick={() => handleMenuItemClick("page")}>
                                {`${t("Translate page to")} ${targetLanguageReadable}`}
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick("full")}>
                                {`${t("Translate full file to")} ${targetLanguageReadable}`}
                            </MenuItem>
                        </Menu>
                    </>
                )}
                <Download>
                    {(props: RenderDownloadProps) => (
                        <Tooltip
                            title={
                                isFullFileTranslated
                                    ? `${t("Download")} ${targetLanguageCode.toUpperCase()} ${t("File")}`
                                    : currentLanguage === "translated"
                                      ? `${t("Download")} ${targetLanguageCode.toUpperCase()} ${t("Page")}`
                                      : t("Download Source File")
                            }>
                            <span>
                                <Button
                                    className="notranslate"
                                    onClick={() =>
                                        currentLanguage === "translated"
                                            ? props.onClick()
                                            : onDownload(fileName, id || "", true)
                                    }
                                    startIcon={
                                        <div
                                            style={{
                                                width: "22px",
                                                height: "22px",
                                                backgroundColor: theme.palette.primary.main,
                                                WebkitMaskImage: `url(${DownArrow})`,
                                                maskImage: `url(${DownArrow})`,
                                                WebkitMaskSize: "cover",
                                                maskSize: "cover",
                                                marginBottom: "3px",
                                            }}
                                        />
                                    }
                                    sx={{
                                        ...(isSmallScreen ? buttonStyleWithoutText : buttonStyleWithText),
                                        paddingTop: "1px",
                                        paddingBottom: "1px",
                                    }}>
                                    {getButtonText(
                                        isFullFileTranslated
                                            ? `${t("Download")} ${targetLanguageCode.toUpperCase()} ${t("File")}`
                                            : currentLanguage === "translated"
                                              ? `${t("Download")} ${targetLanguageCode.toUpperCase()} ${t("Page")}`
                                              : t("Download")
                                    )}
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </Download>
            </>
        )
    }

    const isCitationMode =
        highlightArray &&
        highlightArray.simple.length + highlightArray.semantic.length + highlightArray.answer.length > 0

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement): ReactElement => (
        <Toolbar>
            {(slots: ToolbarSlot): ReactElement => {
                const { Zoom, ZoomIn, ZoomOut, EnterFullScreen } = slots
                const { Search } = searchPluginInstance
                const isSmallScreen = useMediaQuery(theme.breakpoints.down(large ? 600 : 1200))
                return (
                    <div className={styles.toolbarContainer}>
                        <CustomLink
                            underline="none"
                            color="inherit"
                            disabled={!isCitationMode}
                            to={generatePath(RoutesPath.DocumentDetails, {
                                name: fileName,
                                id: null,
                            })}
                            state={{
                                id: id,
                            }}
                            style={{
                                maxWidth: "25%",
                                lineHeight: "1.3",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                marginRight: isSmallScreen ? "0.4rem" : "0.8rem",
                                cursor: "pointer",
                            }}>
                            <Tooltip className="notranslate" title={fileName} placement="top">
                                <Typography
                                    variant="inherit"
                                    fontSize={12}
                                    className="notranslate"
                                    sx={{
                                        transition: "color 0.2s",

                                        "&:hover": {
                                            color: "primary.main",
                                        },
                                    }}>
                                    {processFileName(fileNameCurrent.replace(".pdf", ""))}
                                </Typography>
                            </Tooltip>
                        </CustomLink>
                        {totalPages > 1 && (
                            <Box sx={{ lineHeight: "1.3", fontSize: 12 }}>
                                {isSmallScreen ? (
                                    <>
                                        <Typography variant="inherit">Page:</Typography>
                                        <Typography variant="inherit">
                                            {currentPage.current + 1}/{totalPages}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="inherit">
                                        Page: {currentPage.current + 1}/{totalPages}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        <div className={styles.searchContainer} style={{ display: "flex", flex: 1, maxWidth: "30rem" }}>
                            <CustomSearch Search={Search} />
                        </div>
                        <div className={styles.zoomControls}>
                            <div style={{ padding: "0px", marginLeft: "-10px" }}>
                                <ZoomOut>
                                    {(props: RenderZoomOutProps) => (
                                        <Tooltip title="Zoom out">
                                            <button className={styles.zoomOutButton} onClick={props.onClick}>
                                                <ZoomOutIcon />
                                            </button>
                                        </Tooltip>
                                    )}
                                </ZoomOut>
                            </div>
                            <div style={{ padding: "0px", marginLeft: "-5px" }}>
                                <Zoom />
                            </div>
                            <div style={{ padding: "0px", marginLeft: "-1px" }}>
                                <ZoomIn>
                                    {(props: RenderZoomInProps) => (
                                        <Tooltip title="Zoom in">
                                            <button className={styles.zoomInButton} onClick={props.onClick}>
                                                <ZoomInIcon />
                                            </button>
                                        </Tooltip>
                                    )}
                                </ZoomIn>
                            </div>
                        </div>
                        {id !== undefined && translateComponent()}
                        <div className={styles.moreActionsContainer}>
                            <CustomMoreActionsPopover toolbarSlot={slots} fileName={fileName} id={id} />
                        </div>
                        {(!large || handleClose) && (
                            <IconButton
                                onClick={() => {
                                    if (!large) {
                                        dispatch(setActiveAnalysisPanelTab(""))
                                    } else if (handleClose) {
                                        handleClose()
                                    }
                                }}
                                color="error"
                                className={styles.closeButton}
                                aria-label="close">
                                <ClearIcon />
                            </IconButton>
                        )}
                    </div>
                )
            }}
        </Toolbar>
    )

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs: SidebarTab[]) => [],
        renderToolbar,
        toolbarPlugin: {
            fullScreenPlugin: {
                getFullScreenTarget: pagesContainer => pagesContainer.closest('[data-testid="default-layout__body"]')!,
                // renderExitFullScreenButton: props => <></>,
            },
        },
    })

    const zoomPluginInstance = zoomPlugin()
    const { zoomTo } = zoomPluginInstance

    let height: string
    const heightCalc = large ? "calc(100vh - 350px)" : "calc(100vh - 300px)"

    if (autoHeight) {
        height = "auto"
    } else {
        height = heightCalc
    }

    return (
        <Box sx={{ position: "relative", height: height }}>
            <Box
                sx={{
                    height: height,
                    overflowY: "none",
                    overflowX: "hidden",
                }}>
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[
                        defaultLayoutPluginInstance,
                        searchPluginInstance,
                        zoomPluginInstance,
                        getFilePluginInstance,
                    ]}
                    onDocumentLoad={handleDocumentLoad}
                    onPageChange={handlePageChange}
                    {...(page !== 0 ? { initialPage: page } : {})}
                />
            </Box>
        </Box>
    )
}
