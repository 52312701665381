import { downloadFile, getFileInfo } from "@/api"
import { toast } from "react-toastify"
import { AxiosProgressEvent } from "axios"

export const onDownload = (
    path: string,
    fileId: string,
    downloadSource: boolean = false,
    forceFilename: boolean = false
) => {
    const toastId = toast.loading("Downloading...0%")

    const file = getFileInfo(fileId, null)

    file.then(res => {
        const totalFileSize = res.file_size ? res.file_size : 0

        const progressEvent = (event: AxiosProgressEvent) => {
            toast.update(toastId, {
                render: `Downloading...${Math.round((event.loaded / totalFileSize) * 100)}%`,
            })
        }

        const promise = downloadFile(fileId, progressEvent, path, downloadSource)

        promise
            .then(({ blob, filename }) => {
                const fileName = forceFilename ? path : filename
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", fileName)
                link.style.display = "none"
                document.body.appendChild(link)
                link.click()
                link.remove()
                URL.revokeObjectURL(url)

                toast.update(toastId, {
                    render: `Downloaded ${fileName}`,
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                })
            })
            .catch(error => {
                toast.update(toastId, {
                    render: `Failed to download ${path}`,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                })
            })
    }).catch(error => {
        toast.update(toastId, {
            render: "Failed to get file info",
            type: "error",
            isLoading: false,
            autoClose: 3000,
        })
    })
}
