// Documents.tsx
import React, { useEffect, useRef, useState } from "react"
import { generatePath, Link as RouterLink, useLocation } from "react-router-dom"
import {
    DataGrid,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridCellCheckboxRenderer,
    GridColDef,
    GridFooter,
    GridRowId,
    GridRowSelectionModel,
    GridSortDirection,
} from "@mui/x-data-grid"

import { Button, Container, Grid, IconButton, Link, styled, Tooltip, Typography } from "@mui/material"

import { useFiles } from "@/hooks/useFiles"
import { useModal } from "@/hooks/useModal"
import { onDownload } from "@/utils/download"
import { useDispatch, useSelector } from "react-redux"
import {
    setCurrentFolderPath,
    setDocs,
    setDocsSmartSearchActive,
    setDocumentsVisibleColumns,
    setFilters,
    setPagination,
    setSelectedDocuments,
    setSort,
} from "@/slices/chatSlice"
import { scoreColor } from "@/components/Answer"
import { RoutesPath } from "@/router/config"
import {
    DatabaseFolderIcon,
    DownArrow,
    ExcelFileIcon,
    Folder,
    HtmlFileIcon,
    ImageFileIcon,
    MailFileIcon,
    OtherFileIcon,
    PdfFileIcon,
    PptFileIcon,
    Trash,
    WordFileIcon,
    DBTableFileIcon,
} from "@/assets"
import { FileSchemaResponse } from "@/api"
import { DocumentCustomToolbar } from "./DocumentEditableTextarea"
import { msalInstance } from "@/index"
import { toast } from "react-toastify"
import { format } from "date-fns"
import eventBus from "./eventBus"
import { t } from "i18next"
import NoRowsOverlay from "@/components/NoRowsOverlay"
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner"

export const EmailTypes = ["email", "gmail", "outlook", "icloud mail"]

const getIcon = (name: string, isFolder: boolean, params: any): JSX.Element => {
    name = name.toLowerCase().replace(" (in progress)", "")
    const sheetTypes = ["xls", "xlsx", "ods", "csv"]
    const presentationTypes = ["ppt", "pptx", "odp"]
    const textTypes = ["txt", "doc", "docx", "rtf", "md", "epub", "one", "odt"]
    const imageTypes = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "tif", "webp"]
    const htmlTypes = ["html", "htm", "xhtml", "mhtml", "xml"]
    const emailTypes = ["email"]
    const dbTableTypes = ["sql"]

    const extensionToIconMap: { [key: string]: string } = {
        pdf: PdfFileIcon,
        ...Object.fromEntries(sheetTypes.map(type => [type, ExcelFileIcon])),
        ...Object.fromEntries(presentationTypes.map(type => [type, PptFileIcon])),
        ...Object.fromEntries(textTypes.map(type => [type, WordFileIcon])),
        ...Object.fromEntries(imageTypes.map(type => [type, ImageFileIcon])),
        ...Object.fromEntries(htmlTypes.map(type => [type, HtmlFileIcon])),
        ...Object.fromEntries(emailTypes.map(type => [type, MailFileIcon])),
        ...Object.fromEntries(dbTableTypes.map(type => [type, DBTableFileIcon])),
    }

    let iconPath

    function FileIcon({ iconPath }: { iconPath?: string }) {
        return (
            <div
                style={{
                    display: "flex",
                    opacity: "1",
                }}>
                <img
                    style={{
                        height: "28px",
                        width: "28px",
                    }}
                    src={iconPath}
                    alt="File"
                />
            </div>
        )
    }

    if (isFolder) {
        // for xls types other icon
        if (sheetTypes.includes(name.split(".").pop()?.toLowerCase() as string)) {
            iconPath = ExcelFileIcon
            return FileIcon({ iconPath })
        } else if (emailTypes.includes(name.split(".").pop()?.toLowerCase() as string)) {
            iconPath = MailFileIcon
            return FileIcon({ iconPath })
        } else if (params.file_source === "Oracledb") {
            iconPath = DatabaseFolderIcon
            return FileIcon({ iconPath })
        } else {
            iconPath = Folder
            return (
                <img
                    style={{
                        marginLeft: "1px",
                        marginRight: "1px",
                        height: "26px",
                        width: "26px",
                        marginBottom: "-8px",
                    }}
                    src={iconPath}
                    alt="Folder"
                />
            )
        }
    }

    const extension = name.split(".").pop()?.toLowerCase()

    iconPath = extension && extensionToIconMap[extension] ? extensionToIconMap[extension] : OtherFileIcon
    return FileIcon({ iconPath })
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-row": {
        "&.success-border": {
            borderLeft: `4px solid ${theme.palette.success.main}`,
        },
        "&.warning-border": {
            borderLeft: `4px solid ${theme.palette.warning.main}`,
        },
        "&.error-border": {
            borderLeft: `4px solid ${theme.palette.error.main}`,
        },
        "&.info-border": {
            borderLeft: `0px solid transparent`,
        },
    },
    "& .MuiGrid-root": {
        padding: "0px",
    },
    "& .MuiDataGrid-checkboxInput .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
        color: "#929292",
    },
    "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
        color: "#929292",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
        color: "#929292",
    },
    "& .Mui-checked .MuiSvgIcon-root": {
        color: "inherit", // Use default color when checked
    },
}))

interface Document {
    id: string
}

interface FooterComponentProps {
    selectedDocs: GridRowSelectionModel
    resetSearch: () => void
    submitDeleteAll: () => void
    isSearchFiles?: boolean
    currentFolderId: string | null
}

export const processSource = (source: string | undefined): string => {
    const fixedSource =
        source && source !== "Web Upload"
            ? source
                  .replace(/-/g, " ")
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, match => match.toUpperCase())
            : "Web Upload"
    return fixedSource.replace("E Attachment", "E-Attachment")
}
export const processFileName = (fileName: string): string => {
    const pattern =
        /\.(docx|doc|xlsx|xls|pptx|ppt|odt|ods|odp|txt|rtf|csv|xml|mhtml|xhtml|htm|html|pages|key|numbers|epub|md|email|jpg|jpeg|png|gif|bmp|tiff|tif|webp|one|sql)\.pdf$/i
    const res = pattern.test(fileName) ? fileName.slice(0, -4) : fileName
    if (res) {
        return res
    } else {
        return ""
    }
}

const FooterComponent: React.FC<FooterComponentProps> = ({
    selectedDocs,
    resetSearch,
    submitDeleteAll,
    isSearchFiles,
    currentFolderId,
}) => (
    <>
        <GridFooter />
        <Grid sx={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "10px" }}>
            {(selectedDocs.length > 0 || (isSearchFiles && !currentFolderId)) && (
                <Button
                    className="notranslate"
                    variant="outlined"
                    color="primary"
                    onClick={resetSearch}
                    sx={{ marginRight: "10px" }}>
                    {t("Reset selected documents")}
                </Button>
            )}
            {selectedDocs.length > 0 && (
                <Button
                    className="notranslate"
                    variant="outlined"
                    color="error"
                    onClick={submitDeleteAll}
                    sx={{ marginRight: "10px" }}>
                    {t("Delete all selected")}
                </Button>
            )}
            {/*{currentFolderId && (*/}
            {/*    <Button variant="outlined" color="primary" onClick={goToRootFolder} sx={{ marginRight: "10px" }}>*/}
            {/*        Go to Root Folder*/}
            {/*    </Button>*/}
            {/*)}*/}
        </Grid>
    </>
)

export const Documents = () => {
    const { state = {} } = useLocation()
    const dispatch = useDispatch()
    const {
        pagination,
        filters = { items: [] },
        doc_sort = [{ field: "updated_time_utc", sort: "desc" }],
        docsSmartSearchActive = false,
        currentFolderPath,
        docs,
        selectedDocs,
        allDocuments,

        documentsVisibleColumns,
        docsSearchThreshold,
    } = useSelector((state: any) => state.chat)
    const { confirm } = useModal()
    const { queryClient, data, deleteFile, openFileFolder } = useFiles({
        enabled: state?.enabled,
    })

    const [pageLoaded, setPageLoaded] = useState(false)
    const [hoveredRowId, setHoveredRowId] = useState<string | null>(null)
    const currentUserId = msalInstance.getActiveAccount()?.localAccountId
    const [folderIds, setFolderIds] = useState<string[]>([])
    const [visibleRows, setVisibleRows] = useState<string[]>([])
    const [isChangingFolder, setIsChangingFolder] = useState(false)
    const currentFolderIdRef = useRef<string | null>(null)
    const [folderPages, setFolderPages] = useState<{ [key: string]: number }>({})

    useEffect(() => {
        if (data) {
            const visibleIds = data.map((item: FileSchemaResponse) => item.id)
            setVisibleRows(visibleIds)
            setIsChangingFolder(false)
        }
    }, [data])

    useEffect(() => {
        const handleBackButtonClick = () => {
            goToParentFolder()
        }

        eventBus.on("backButtonClick", handleBackButtonClick)

        return () => {
            eventBus.off("backButtonClick", handleBackButtonClick)
        }
    }, [currentFolderPath])

    const goToRootFolder = () => {
        setIsChangingFolder(true)
        const currentPage = folderPages[""] || 0
        dispatch(setDocsSmartSearchActive(false))
        openFileFolder.mutate(
            { folder_name: "", alias: "" },
            {
                onSuccess: () => {
                    setTimeout(() => {
                        dispatch(setPagination({ pageSize: pagination.pageSize, page: currentPage }))
                    }, 1)
                },
            }
        )
    }

    const openFolder = (folderId: string, folderName: string) => {
        setIsChangingFolder(true)
        setFolderPages(prev => ({ ...prev, [currentFolderPath || ""]: pagination.page }))

        currentFolderIdRef.current = folderId

        openFileFolder.mutate(
            { folder_name: folderId, alias: folderName },
            {
                onSuccess: () => {
                    setTimeout(() => {
                        dispatch(setPagination({ pageSize: pagination.pageSize, page: 0 }))
                    }, 1)
                },
            }
        )
    }

    const goToParentFolder = () => {
        if (currentFolderPath) {
            setFolderPages(prev => ({ ...prev, [currentFolderPath || ""]: pagination.page }))

            if (!currentFolderPath.includes("/")) {
                goToRootFolder()
                return
            }
            const parentFolderId = getParentFolderId(currentFolderPath || "") || null
            setIsChangingFolder(true)
            dispatch(setDocsSmartSearchActive(false))
            if (parentFolderId) {
                const parentPage = folderPages[parentFolderId] || 0

                openFileFolder.mutate(
                    { folder_name: parentFolderId, alias: "" },
                    {
                        onSuccess: () => {
                            setTimeout(() => {
                                dispatch(setPagination({ pageSize: pagination.pageSize, page: parentPage }))
                            }, 1)
                        },
                    }
                )
            }
        }
    }

    const getParentFolderId = (folderId: string): string | null => {
        return folderId.split("/").slice(0, -1).join("/")
    }

    const folderSortComparator = (sortDirection: GridSortDirection) => {
        return (value1: any, value2: any, rowData1: any, rowData2: any): number => {
            function isFolder(rowData: { id: string }, folderIds: string[]) {
                const isIdValid = rowData.id && folderIds.includes(rowData.id)
                const isNotFile = !["xls", "ods", "csv", "xlsx", ".email"].some(ext => rowData.id.includes(ext))

                return isIdValid && isNotFile
            }

            const isFolder1 = isFolder(rowData1, folderIds)
            const isFolder2 = isFolder(rowData2, folderIds)

            if (isFolder1 && !isFolder2) {
                return -1
            }
            if (!isFolder1 && isFolder2) {
                return 1
            }
            if (value1 !== undefined && value2 !== undefined) {
                try {
                    if (typeof value1 === "string" && typeof value2 === "string") {
                        return value1.localeCompare(value2) * (sortDirection === "asc" ? 1 : -1)
                    } else if (typeof value1 === "number" && typeof value2 === "number") {
                        return (value1 - value2) * (sortDirection === "asc" ? 1 : -1)
                    }
                    console.log("Error in sorting", `rowData1: ${rowData1.id}, rowData2: ${rowData2.id}`)
                } catch (e) {
                    console.log(
                        "Error in sorting",
                        e,
                        `value1: ${value1}, value2: ${value2}`,
                        "type of value1",
                        typeof value1,
                        "type of value2",
                        typeof value2
                    )
                    return 0
                }
            }
            return 0
        }
    }

    useEffect(() => {
        setPageLoaded(true)
    }, [])

    useEffect(() => {
        // When the page has loaded and smart search is not active, re-fetch the default documents.
        // console.log("docsSmartSearchActive", docsSmartSearchActive)
        if (pageLoaded && !docsSmartSearchActive && !currentFolderPath) {
            queryClient.refetchQueries(["files", currentFolderPath])
            if (state && !state?.enabled) {
                state.enabled = true
            }
        }
    }, [pageLoaded, docsSmartSearchActive, queryClient, currentFolderPath])

    useEffect(() => {
        // console.log(`Smart search is ${state?.enabled}`)
        if (state?.enabled === false) {
            const smartSearchedFiles = data?.map(item => {
                return item.id
            })
            if (smartSearchedFiles) {
                // console.log("smartSearchedFiles", smartSearchedFiles)
                dispatch(setDocs(smartSearchedFiles))
                dispatch(setFilters({ items: [] }))
                dispatch(setSort([]))
                dispatch(setPagination({ pageSize: pagination.pageSize, page: pagination.page }))
                dispatch(setDocsSmartSearchActive(true))

                setTimeout(() => {
                    dispatch(setSelectedDocuments(smartSearchedFiles))
                }, 100)
            }
        }
    }, [data])

    const resetSearch = () => {
        // console.log("resetSearch")
        queryClient.refetchQueries(["files", currentFolderPath])
        dispatch(setFilters({ items: [] }))
        dispatch(setPagination({ pageSize: pagination.pageSize, page: 0 }))
        dispatch(setSort([{ field: "updated_time_utc", sort: "desc" }]))
        if (state && !state?.enabled) {
            state.enabled = true
        }
        dispatch(setDocsSmartSearchActive(false))
        dispatch(setCurrentFolderPath(null))
        dispatch(setSelectedDocuments([]))
    }

    // First, create an index of files by folder for quick lookup
    function createFolderIndex(allFolders: Array<{ file_folder: string; id: string }>) {
        const folderIndex = new Map<string, Set<string>>()

        allFolders.forEach(doc => {
            const folder = doc.file_folder
            // Add document to its immediate folder
            if (!folderIndex.has(folder)) {
                folderIndex.set(folder, new Set())
            }
            folderIndex.get(folder)!.add(doc.id)

            // Add document to all parent folders
            let parentPath = folder
            while (parentPath.includes("/")) {
                parentPath = parentPath.substring(0, parentPath.lastIndexOf("/"))
                if (!folderIndex.has(parentPath)) {
                    folderIndex.set(parentPath, new Set())
                }
                folderIndex.get(parentPath)!.add(doc.id)
            }
        })

        return folderIndex
    }

    function updateFolderSelection(
        folderIds: string[],
        allFolders: Array<{ file_folder: string; id: string }>,
        newSelection: Set<string>
    ) {
        // Create index once
        const folderIndex = createFolderIndex(allFolders)

        // Check each folder
        folderIds.forEach(folderId => {
            const folderFiles = folderIndex.get(folderId)
            if (!folderFiles) return

            // Check if all files in the folder are selected
            const hasAllDocs = Array.from(folderFiles).every(fileId => newSelection.has(fileId))
            hasAllDocs ? newSelection.add(folderId) : newSelection.delete(folderId)
        })

        return newSelection
    }

    const handleSelectionModelChange = (newSelectionModel: GridRowSelectionModel) => {
        if (isChangingFolder) {
            return // Don't update selection while changing folders
        }

        const newSelection = new Set(selectedDocs)

        // Select documents within selected folders
        newSelectionModel.forEach(id => {
            if (folderIds.includes(id as string)) {
                allDocuments.forEach((doc: { file_folder: GridRowId; id: unknown }) => {
                    if (
                        typeof doc.file_folder === "string" &&
                        (doc.file_folder === id || doc.file_folder.startsWith(`${id}/`))
                    ) {
                        newSelection.add(doc.id)
                    }
                    if (typeof doc.id === "string" && doc.id.startsWith(id as string)) {
                        newSelection.add(doc.id)
                    }
                })
            }

            if (visibleRows.includes(id as string)) {
                newSelection.add(id as string)
            }
        })
        visibleRows.forEach(id => {
            if (!newSelectionModel.includes(id) && newSelection.has(id)) {
                newSelection.delete(id)
            }
        })

        new Set(selectedDocs).forEach(id => {
            if (folderIds.includes(id as string) && !newSelection.has(id)) {
                const folderDocs = allDocuments.filter((doc: { file_folder: any }) => {
                    return (
                        typeof doc.file_folder === "string" &&
                        (doc.file_folder === id || doc.file_folder.startsWith(`${id}/`))
                    )
                })

                folderDocs.forEach((doc: { id: unknown }) => {
                    newSelection.delete(doc.id)
                })
            }
        })

        const allFolders = allDocuments.filter((doc: { file_folder: any }) => typeof doc.file_folder === "string")
        // @ts-ignore
        updateFolderSelection(folderIds, allFolders, newSelection)
        // console.log("newSelection", newSelection)
        dispatch(setSelectedDocuments(Array.from(newSelection)))
        // setPreviousSelectedDocs(newSelectionModel)
    }

    const submitDelete = (id: string) => {
        const isFolder = folderIds.includes(id)
        let filesToDelete: string[] = [id]

        if (isFolder) {
            // Function to recursively get all files in a folder
            const getFilesInFolder = (folderId: string): string[] => {
                return allDocuments
                    .filter((doc: { file_folder?: string; id: string }) => {
                        if (!doc.file_folder) return false
                        return doc.file_folder === folderId || doc.file_folder.startsWith(`${folderId}/`)
                    })
                    .map((doc: { id: string }) => doc.id)
            }

            filesToDelete = getFilesInFolder(id)
        }

        const is_able_to_delete_selected_files = filesToDelete.every((id: string) => {
            const file = allDocuments?.find((file: { id: string }) => file.id === id)
            return file?.uploaded_by_user_id === currentUserId
        })

        if (!is_able_to_delete_selected_files) {
            toast.error("You can only delete files uploaded by you")
            return
        }

        const is_some_files_has_folder = filesToDelete.some((id: string) => {
            const file = allDocuments?.find((file: { id: string }) => file.id === id)
            return file?.file_folder !== undefined
        })

        const numFilesToDelete = filesToDelete.length
        let confirmMessage = isFolder
            ? `Are you sure you want to <b>permanently</b> delete this folder and the <b>${numFilesToDelete}</b> files it contains?`
            : `Are you sure you want to delete this file <b>permanently</b>?`

        if (is_some_files_has_folder) {
            confirmMessage +=
                "<br><small>Note: Some files are synced from connectors and may be re-uploaded during the next sync.</small>"
        }

        confirm(confirmMessage, {
            onOk: () => {
                deleteFile.mutate(
                    { files: filesToDelete },
                    {
                        onSuccess: () => {
                            const updatedDocs = docs.filter((item: Document) => !filesToDelete.includes(item.id))
                            dispatch(setDocs(updatedDocs))
                            // toast.success(
                            //     isFolder ? "Folder and its contents deleted successfully" : "File deleted successfully"
                            // )
                        },
                        onError: (error: any) => {
                            toast.error(`Error deleting ${isFolder ? "folder" : "file"}: ${error.message}`)
                        },
                    }
                )
            },
        })
    }

    const submitDeleteAll = () => {
        const selectedDocsWithoutFolders = selectedDocs.filter((id: string) => !folderIds.includes(id))
        const selectedDocsList = selectedDocsWithoutFolders as Array<string>
        const is_able_to_delete_selected_files = selectedDocsList.every((id: string) => {
            const file = allDocuments?.find((file: { id: string }) => file.id === id)
            return file?.uploaded_by_user_id === currentUserId
        })
        const is_able_to_delete_selected_files2 = selectedDocsList.every((id: string) => {
            const file = allDocuments?.find((file: { id: string }) => file.id === id)
            return file?.is_real_time === false || file?.is_real_time === undefined
        })

        if (!is_able_to_delete_selected_files2) {
            toast.error("Can be removed only from connectors page")
            return
        }

        if (!is_able_to_delete_selected_files) {
            toast.error("You can only delete files uploaded by you")
            return
        }

        const numFilesToDelete = selectedDocsList.length
        let confirmMessage = `Are you sure you want to <b>permanently</b> delete <b>${numFilesToDelete}</b> files?`
        const is_some_files_has_folder = selectedDocsList.some((id: string) => {
            const file = allDocuments?.find((file: { id: string }) => file.id === id)
            return file?.file_folder !== undefined
        })

        if (is_some_files_has_folder) {
            confirmMessage +=
                "<br><small>Note: Some files are synced from connectors and may be re-uploaded during the next sync.</small>"
        }

        confirm(confirmMessage, {
            onOk: () => {
                deleteFile.mutate(
                    { files: selectedDocsWithoutFolders as Array<string> },
                    {
                        onSuccess: () => {
                            dispatch(setDocs([]))
                        },
                    }
                )
            },
        })
    }

    const renderSourceColumn = (params: any) => {
        const file = params.row

        let source = file.file_source

        if (file.is_folder) {
            // Keep the original source if it's a folder
        } else if (currentUserId != file.uploaded_by_user_id && file.is_folder === false) {
            const is_email = EmailTypes.includes(params.row.file_source.toLowerCase())
            if (is_email) {
                source = "Shared Email"
            } else {
                source = file.connector_name
            }
        } else {
            if (source && source !== "Web upload") {
                if (source === "Imap") {
                    source = "Email"
                }
            } else {
                source = "Web upload"
            }
        }
        return (
            <Typography className="notranslate" variant="body1">
                {source}
            </Typography>
        )
    }

    const columns: GridColDef[] = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            display: "flex",
            renderCell: params => {
                return (
                    <Tooltip
                        PopperProps={{ disablePortal: true }}
                        placement={"top"}
                        title={"Select this file for conversation"}
                        followCursor={false}>
                        <GridCellCheckboxRenderer {...params} />
                    </Tooltip>
                )
            },
            width: 42,
            maxWidth: 42,
            minWidth: 42,
            type: "singleSelect",
            getSortComparator: folderSortComparator,
        },
        {
            field: "file_name",
            headerName: "Name",
            minWidth: 100,
            display: "flex",
            flex: 4,
            getSortComparator: folderSortComparator,

            renderCell: params => {
                const isFolder = params.row.is_folder
                const isRealTime = params.row.is_real_time
                const iconElement = getIcon(params.row.file_name, isFolder, params.row)

                return (
                    <div
                        className="notranslate"
                        onMouseEnter={() => setHoveredRowId(params.row.id)}
                        onMouseLeave={() => setHoveredRowId(null)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "pointer",
                            width: "100%",
                            paddingLeft: "0px", // Adjust padding to reduce space
                            // marginLeft: "5px",
                        }}>
                        {isFolder ? (
                            <Button
                                component={RouterLink}
                                to="#"
                                onClick={() => openFolder(params.row.id, params.row.file_name)}
                                state={{
                                    id: params.row.id,
                                    paginationModel: pagination,
                                    enabled: state?.enabled,
                                    searchParameters: state?.searchParameters,
                                }}
                                sx={{
                                    color: "inherit",
                                    textTransform: "none",
                                    justifyContent: "flex-start",
                                    padding: "6px 6px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "0px",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}>
                                <Tooltip className="notranslate" title={params.row.file_name} placement="top">
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <div>{iconElement}</div>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                marginLeft: "10px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                cursor: "pointer",
                                                flexGrow: 1,
                                                width: "100%",
                                            }}>
                                            {params.row.file_name.replace(".email", "")}
                                            {params.row.score &&
                                                ` (${parseFloat((params.row.score * 100).toFixed(0))}%)`}
                                        </Typography>
                                    </div>
                                </Tooltip>
                            </Button>
                        ) : (
                            <Link
                                underline="none"
                                color="inherit"
                                component={RouterLink}
                                to={
                                    !isRealTime
                                        ? generatePath(RoutesPath.DocumentDetails, {
                                              name: params.row.file_name,
                                              id: null,
                                          })
                                        : ""
                                }
                                state={{
                                    id: params.row.id,
                                    paginationModel: pagination,
                                    enabled: state?.enabled,
                                    searchParameters: state?.searchParameters,
                                }}
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    cursor: isRealTime ? "default" : "pointer",
                                }}>
                                <Tooltip className="notranslate" title={params.row.file_name} placement="top">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                        }}>
                                        <div>{iconElement}</div>

                                        <Typography
                                            variant="body1"
                                            style={{
                                                marginLeft: "10px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                cursor: isRealTime ? "default" : "pointer",
                                                width: "100%",
                                            }}>
                                            {params.row.file_name.replace(".email", "").replace(".sql", "")}
                                            {params.row.score &&
                                                ` (${parseFloat((params.row.score * 100).toFixed(0))}%)`}
                                        </Typography>
                                    </div>
                                </Tooltip>
                            </Link>
                        )}
                        {hoveredRowId === params.row.id && !params.row.file_name.includes("(in progress)") && (
                            <>
                                {!isFolder && !isRealTime && (
                                    <IconButton
                                        onClick={() => onDownload(params.row.file_initial_name, params.row.id, true)}
                                        sx={{ "&:hover": { backgroundColor: "#F1F1F1" }, marginRight: "-10px" }}>
                                        <img
                                            style={{ minWidth: "10px", maxWidth: "22px" }}
                                            src={DownArrow}
                                            alt="DownArrow"
                                        />
                                    </IconButton>
                                )}
                                {!isRealTime && (
                                    <IconButton
                                        onClick={() => submitDelete(params.row.id)}
                                        sx={{ "&:hover": { backgroundColor: "#F1F1F1" } }}>
                                        <img style={{ minWidth: "10px", maxWidth: "22px" }} src={Trash} alt="Trash" />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </div>
                )
            },
        },
        {
            field: "from_email",
            headerName: "From",
            minWidth: 150,
            // maxWidth: 300,
            display: "flex",
            flex: 3,
            getSortComparator: folderSortComparator,
            disableColumnMenu: true,
            renderCell: params => {
                if (!EmailTypes.includes(params.row.file_source.toLowerCase())) return null
                return (
                    <Tooltip
                        className="notranslate"
                        title={params.row.metadata?.from_email ? params.row.metadata?.from_email : ""}>
                        <Typography className="notranslate">
                            {params.row.metadata?.from_email ? params.row.metadata?.from_email : ""}
                        </Typography>
                    </Tooltip>
                )
            },
        },
        {
            field: "to_email",
            headerName: "To",
            minWidth: 200,
            display: "flex",
            // maxWidth: 400,
            flex: 3,
            getSortComparator: folderSortComparator,
            disableColumnMenu: true,
            renderCell: params => {
                if (!EmailTypes.includes(params.row.file_source.toLowerCase())) return null
                return (
                    <Tooltip
                        className="notranslate"
                        title={params.row.metadata?.to_emails ? params.row.metadata?.to_emails : ""}>
                        <Typography className="notranslate">
                            {params.row.metadata?.to_emails ? params.row.metadata?.to_emails : ""}
                        </Typography>
                    </Tooltip>
                )
            },
        },
        {
            field: "file_source",
            headerName: "Source",
            minWidth: 110,
            display: "flex",
            maxWidth: 130,
            flex: 1,
            getSortComparator: folderSortComparator,
            renderCell: renderSourceColumn,
        },

        {
            field: "file_size",
            headerName: "Size",
            display: "flex",
            minWidth: 75,
            maxWidth: 90,
            flex: 1,
            getSortComparator: folderSortComparator,
            disableColumnMenu: true,
            renderCell: params => {
                const sizeInMB = params.row.file_size / 1024 / 1024

                if (isNaN(sizeInMB)) {
                    return <Typography variant="body1">-</Typography>
                }
                return <Typography variant="body1">{`${Math.max(sizeInMB, 0.01).toFixed(2)} MB`}</Typography>
            },
        },

        {
            field: "updated_time_utc",
            headerName: "Date",
            minWidth: 185,
            display: "flex",
            maxWidth: 200,
            flex: 1,
            getSortComparator: folderSortComparator,
            disableColumnMenu: true,
            resizable: false,

            renderCell: params => {
                if (params.row.updated_time_utc === undefined && params.row.metadata?.sent_date === undefined) {
                    return <Typography variant="body1">-</Typography>
                }

                if (
                    params.row.is_folder ||
                    (!EmailTypes.includes(params.row.file_source.toLowerCase()) &&
                        params.row.file_source.toLowerCase() !== "e-attachment")
                ) {
                    return (
                        <Typography variant="body1">
                            {format(new Date(params.row.updated_time_utc ?? " "), "yyyy MMM dd, HH:mm")}
                        </Typography>
                    )
                } else if (params.row.metadata?.sent_date) {
                    return (
                        <Typography variant="body1">
                            {format(new Date(params.row.metadata?.sent_date ?? " "), "yyyy MMM dd, HH:mm")}
                        </Typography>
                    )
                }
            },
        },
    ]

    const processData = (data: FileSchemaResponse[] | undefined): FileSchemaResponse[] => {
        return data
            ? data
                  .filter(row => {
                      if (docsSearchThreshold === 0) {
                          return true
                      }
                      if (row.score === undefined) {
                          return true
                      }
                      return row.score === null || parseFloat(row.score) >= docsSearchThreshold / 100
                  })
                  .map((row: FileSchemaResponse) => ({
                      ...row,
                      file_source: processSource(row.file_source),
                      file_name:
                          processFileName(row.file_name) || (row.summary ? `${row.summary.slice(0, 100)}...` : ""),
                      file_initial_name: row.file_name,
                  }))
            : []
    }

    useEffect(() => {
        if (allDocuments || data) {
            let allDocuments2: FileSchemaResponse[]
            if (allDocuments && data) {
                allDocuments2 = [...allDocuments, ...data]
            } else {
                allDocuments2 = allDocuments || data
            }
            const folderIds = allDocuments2
                .filter((item: FileSchemaResponse) => item.is_folder)
                .map((item: FileSchemaResponse) => item.id)

            const uniqueFolderIds = [...new Set(folderIds)]
            setFolderIds(uniqueFolderIds)
        }
    }, [allDocuments, data])

    return (
        <Container sx={{ padding: "20px 0", flex: 1 }}>
            <StyledDataGrid
                sx={{
                    boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.25)",
                    maxHeight: "calc(100vh - 300px)",
                    "& .MuiDataGrid-columnHeader": {
                        height: "50px !important",
                        bgcolor: "#fff",
                        borderRadius: 0,
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-horizontal": {
                        display: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        overflowX: "hidden",
                    },
                }}
                columnHeaderHeight={50}
                rowHeight={49}
                paginationModel={pagination}
                onPaginationModelChange={newModel => dispatch(setPagination(newModel))}
                filterModel={filters}
                onFilterModelChange={newModel => dispatch(setFilters(newModel))}
                sortModel={doc_sort}
                onSortModelChange={newModel => dispatch(setSort(newModel))}
                pageSizeOptions={[10, 15, 20]}
                rows={data ? processData(data) : []}
                columns={columns}
                columnVisibilityModel={documentsVisibleColumns}
                onColumnVisibilityModelChange={newModel => dispatch(setDocumentsVisibleColumns(newModel))}
                slots={{
                    toolbar: DocumentCustomToolbar,
                    noRowsOverlay: () =>
                        data === undefined ? <LoadingSpinner /> : <NoRowsOverlay message="No Files" />,
                    footer: () => (
                        <FooterComponent
                            selectedDocs={selectedDocs}
                            resetSearch={resetSearch}
                            submitDeleteAll={submitDeleteAll}
                            isSearchFiles={docsSmartSearchActive}
                            currentFolderId={currentFolderPath}
                        />
                    ),
                }}
                loading={!pageLoaded}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={handleSelectionModelChange}
                rowSelectionModel={selectedDocs.filter((id: string) => visibleRows.includes(id))}
                getRowClassName={params => `${scoreColor(params.row.score)}-border`}
            />
        </Container>
    )
}
